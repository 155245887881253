import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serialize } from "utils";

export const lockApi = createApi({
    reducerPath: "lockApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    tagTypes: ["LOCKS"],
    endpoints: (builder) => ({
        listLocks: builder.query({
            query: ({ page = 1, search, beneficiary, chain }) => {
                if (!beneficiary || !chain) return;
                return `/lock?${serialize({
                    page,
                    search,
                    beneficiary,
                    chain,
                })}`;
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            // transformResponse: (response, meta, arg) => response,
            merge: (currentCache, newItem) => {
                console.log("currentCache", currentCache.data);
                console.log("newItem", newItem);
                currentCache.data.push(...newItem?.data);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
            providesTags: ["LOCKS"],
        }),
    }),
});

export const { useListLocksQuery } = lockApi;
