export const CHAIN_NAME = {
	MUMBAI: "MUMBAI",
};

export const dayName = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const tokenUrls = [
	{
		name: CHAIN_NAME.MUMBAI,
		currency: "MATIC",
		tokenUrl: "https://apiv5.paraswap.io/tokens/137",
		chainId: 80001,
		logo: "/assets/images/icons/Chain4.png",
		scanUrl: "https://polygonscan.com/tx/",
		contractAddress: "",
	},
];

export const RPC_URLS = {
	1: "https://mainnet.infura.io/v3/93b3db09c41f4dedab011bd43f8817d7",
	5: "https://goerli.infura.io/v3/93b3db09c41f4dedab011bd43f8817d7",
	80001: "https://polygon-mumbai.infura.io/v3/4458cf4d1689497b9a38b1d6bbf05e78",
	97: "https://data-seed-prebsc-1-s1.binance.org:8545",
	31337: "http://127.0.0.1:8545",
};

export const nullAddress = "0x0000000000000000000000000000000000000000";
export const allowanceAmount = "9999999999999999999999999";

export const SUPPOTED_CHAINID = [80001];

export const BLOCK_PER_SECOND = {
	1: 12,
	137: 2,
	80001: 3,
	31337: 2,
};

export const ANUAL_BLOCK_MINT = {
	1: 2400000,
	137: 43200 * 365,
	80001: 43200 * 365,
};

export const IDO_PRE_SALE_FACTORY_ADDRESS = {
	31337: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
	80001: "0xB9ca9D0894832aC6432FeB2A63dcD0F8825C4dE1",
	// 80001: "0x043C3D58956117c54147A3ed0DffC5733BE6D9FB",
};

// AirDrop
export const AIRDROP_ADDRESS = {
	80001: "0x0d7cc1CE875946173e11df043c2B618227C4D201",
};

//lock
export const LOCK_ADDRESS = {
	80001: "0x9F676BC639359c43502095bFd0f6334c887fA609",
};

//fairlaunch
export const IDO_FAIRLAUNCH_FACTORY_ADDRESS = {
	80001: "0xa1F56D9179483dbFfCc02fcda1C89a7657FB486b",
};

export const PAIR_COIN_ADDRESS = {
	MATIC: "0x0000000000000000000000000000000000000000",
	BUSD: "0x9A2ABAA45f21d245fF2F440a6b8e6AFDD19f1492",
};

export const NATIVE_TOKENS = {
	80001: [
		{
			address: "0x0000000000000000000000000000000000000000",
			name: "MATIC",
			type: "native",
			symbol: "MATIC",
			logo: "/assets/images/icons/Chain4.png",
		},
		{
			address: "0x9A2ABAA45f21d245fF2F440a6b8e6AFDD19f1492",
			name: "BUSD",
			type: "stable",
			symbol: "BUSD",
			logo: "/assets/images/icons/phantom.png",
		},
	],
};

export const CHAIN_NAME_PER_ID = {
	80001: "MUMBAI",
	1: "ETHEREUM",
	97: "BINANCETESTNET",
	56: "BINANCE",
	137: "POLYGON",
	43114: "AVALANCHE",
	250: "FANTOM",
	10: "OPTIMISUM",
	42161: "ARBRIUTUM",
	25: "CRONOS",
	1231: "TRON",
};

export const NATIVE_TOKEN_FROM_CHAINID = {
	1: "ETH",
	137: "MATIC",
	80001: "MATIC",
	97: "BNB",
	56: "BNB",
	43114: "AVAX",
	250: "FTM",
};

export const NATIVE_TOKENS_FROM_CHAIN_NAME = {
	MUMBAI: "MATIC",
	ETHEREUM: "ETH",
	BINANCETESTNET: "BNB",
	BINANCE: "BNB",
	POLYGON: "MATIC",
	AVALANCHE: "AVAX",
	FANTOM: "FTM",
	CRONOS: "CRX",
};

export const SUPPORTED_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/gif",
	"image/png",
];

export const IDO_TYPE = {
	FAIRLAUNCH: "FairLaunch",
	PRESALE: "PreSale",
};

export const BLOCK_EXPLORER = {
	1: "https://etherscan.io/tx/",
	56: "https://bscscan.com/tx/",
	137: "https://polygonscan.com/tx/",
	5: "https://goerli.etherscan.io/tx/",
	80001: "https://mumbai.polygonscan.com/tx/",
};
