import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    IDO_PRE_SALE_FACTORY_ADDRESS,
    IDO_TYPE,
    PAIR_COIN_ADDRESS,
} from "constant";
import {
    fairLaunchFactoryContract,
    web3Erc20Contract,
    web3IdoFactoryContract,
} from "contract";
import { toast } from "react-toastify";
import { idoApplyTransactionAction } from "redux/reducers/idoApplySlice";
import { createIdoService } from "redux/services/idoService";
import {
    fromWei,
    getTotalAmountOfApproveForFairLaunch,
    getTotalAmountOfApproveForPresale,
    revertedError,
    toWei,
    unixTimeStamp,
    uploadToIpfs,
} from "utils";

export const getTokenDetails = createAsyncThunk(
    "idoApply/getTokenDetails",
    async (payload, { getState, dispatch, rejectWithValue }) => {
        try {
            const _erc20Token = web3Erc20Contract({
                address: payload.tokenAddress,
                chainId: payload.chainId,
            });
            const [name, symbol, decimals, totalSupply] = await Promise.all([
                _erc20Token.methods.name().call(),
                _erc20Token.methods.symbol().call(),
                _erc20Token.methods.decimals().call(),
                _erc20Token.methods.totalSupply().call(),
            ]);

            return {
                ...payload,
                name,
                symbol,
                decimals,
                totalSupply: fromWei(totalSupply, decimals),
            };
        } catch (err) {
            console.log("err", err);
            toast.error("Invalid token address");
            return rejectWithValue(err.message);
        }
    }
);

export const checkAllowenceAction = createAsyncThunk(
    "idoApply/checkAllowenceAction",
    async (payload, { rejectWithValue }) => {
        try {
            const _erc20Contract = web3Erc20Contract({
                address: payload?.address,
                chainId: payload?.chainId,
            });

            if (!_erc20Contract) {
                return rejectWithValue("No contract found!");
            }

            const result = await _erc20Contract.methods
                .allowance(payload?.account, payload?.spender)
                .call();
            console.log("result", result);
            return result?.toString();
        } catch (err) {
            console.log("err", err);
            toast.error("allowance Invalid token address");
            return rejectWithValue(err.message);
        }
    }
);

export const confirmAllowenceAction = createAsyncThunk(
    "idoApply/confirmAllowenceAction",
    async (payload, { dispatch, rejectWithValue }) => {
        try {
            console.log("payload", payload);
            let _erc20Contract = web3Erc20Contract({
                address: payload?.tokenAddress,
                chainId: payload?.chainId,
            });
            if (!_erc20Contract) {
                return rejectWithValue("No contract found!");
            }

            await _erc20Contract.methods
                .approve(payload?.spender, payload?.amount)
                .send(
                    { from: payload?.account },
                    function (error, transactionHash) {
                        if (error) {
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_approve",
                                        hash: null,
                                        status: "failed",
                                        result: {},
                                    },
                                    error: revertedError(error),
                                })
                            );
                        } else {
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_approve",
                                        hash: transactionHash,
                                        status: "pending",
                                        result: {},
                                    },
                                })
                            );
                        }
                    }
                )
                .on("receipt", async function (receipt) {
                    console.log("receipt", receipt);
                    dispatch(
                        idoApplyTransactionAction({
                            transaction: {
                                type: "idoApply_approve",
                                hash: null,
                                status: "success",
                                result: receipt,
                            },
                        })
                    );
                })
                .on("error", async function (error) {
                    console.log("error", error);
                    idoApplyTransactionAction({
                        transaction: {
                            type: "idoApply_approve",
                            hash: null,
                            status: "failed",
                            result: {},
                        },
                        error: revertedError(error, 2),
                    });
                });
            return;
        } catch (err) {
            const parsedError = JSON.stringify(err.message);
            if (parsedError.includes("reverted with reason ")) {
                return rejectWithValue(revertedError(err));
            }
            return rejectWithValue(err.message);
        }
    }
);

export const createPresaleIdoAction = createAsyncThunk(
    "idoApply/createPresaleIdoAction",
    async ({ chainId, account }, { getState, dispatch, rejectWithValue }) => {
        console.log("hear");
        try {
            const _idoPresaleFactoryContract = web3IdoFactoryContract({
                chainId,
            });
            if (!_idoPresaleFactoryContract) {
                return rejectWithValue("No contract found");
            }

            const { idoApply } = getState();

            const { formDetails, preFee } = idoApply;

            const allocation = getTotalAmountOfApproveForPresale({
                tokenRate: formDetails?.tokenRate,
                hardCap: formDetails?.hardCap,
                liquidityPc: formDetails?.liquidity,
                LiquidityRate: formDetails?.liquidityRate, //chage
            });
            const idoDetails = {
                TokenAddress: formDetails?.tokenAddress,
                TokenAllocation: toWei(
                    allocation,
                    formDetails?.decimals?.toString()
                ),
                TokenRate: toWei(
                    formDetails?.tokenRate,
                    formDetails?.decimals?.toString()
                ),
                SoftCap: toWei(
                    formDetails?.softCap,
                    formDetails?.decimals?.toString()
                ),
                HardCap: toWei(
                    formDetails?.hardCap,
                    formDetails?.decimals?.toString()
                ),
                MinBuyPerUser: toWei(
                    formDetails?.minBuy,
                    formDetails?.decimals?.toString()
                ),
                MaxBuyPerUser: toWei(
                    formDetails?.maxBuy,
                    formDetails?.decimals?.toString()
                ),
                StartTime: unixTimeStamp(formDetails?.idoStartDate),
                EndTime: unixTimeStamp(formDetails?.idoEndDate),
                DexListingRate: toWei(
                    formDetails?.liquidityRate,
                    formDetails?.decimals?.toString()
                ),
                LiquidityPC: formDetails?.liquidity,
                Refund: formDetails?.refund === "refund",
                AutoListing: formDetails?.tokenListingType === "Auto",
                UseWhiteList: formDetails?.whitelist === "true",
            };

            console.log("idoidoDetailsDe", idoDetails);
            console.log(
                "PAIR_COIN_ADDRESS[formDetails?.pairCoin]",
                PAIR_COIN_ADDRESS[formDetails?.pairCoin]
            );
            console.log("account", account);
            const [symbolImg, coverImg] = await Promise.all([
                uploadToIpfs(formDetails?.projectCover),
                uploadToIpfs(formDetails?.coverImage),
            ]);

            await createIdoService({
                ...formDetails,
                projectCover: `${process.env.REACT_APP_IPFS_GATEWAY}${symbolImg?.data?.IpfsHash}`,
                coverImage: `${process.env.REACT_APP_IPFS_GATEWAY}${coverImg?.data?.IpfsHash}`,
                idoStartDate: new Date(formDetails?.idoStartDate).toISOString(),
                idoEndDate: new Date(formDetails?.idoEndDate).toISOString(),
            });
            await _idoPresaleFactoryContract.methods
                .createIDO(idoDetails, PAIR_COIN_ADDRESS[formDetails?.pairCoin])
                .send(
                    { from: account, value: preFee },
                    async function (error, transactionHash) {
                        if (error) {
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_presale_createIdo",
                                        hash: null,
                                        status: "failed",
                                        result: {},
                                    },
                                    error: revertedError(error),
                                })
                            );
                        } else {
                            //await createIdoService(formDetails);
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_presale_createIdo",
                                        hash: transactionHash,
                                        status: "pending",
                                        result: {},
                                    },
                                })
                            );
                        }
                    }
                )
                .on("receipt", async function (receipt) {
                    console.log("receipt", receipt);
                    dispatch(
                        idoApplyTransactionAction({
                            transaction: {
                                type: "idoApply_presale_createIdo",
                                hash: null,
                                status: "success",
                                result: receipt,
                            },
                        })
                    );
                })
                .on("error", async function (error) {
                    console.log("error", error);
                    if (error?.code === 4001) return;
                    dispatch(
                        idoApplyTransactionAction({
                            transaction: {
                                type: "idoApply_presale_createIdo",
                                hash: null,
                                status: "failed",
                                result: {},
                            },
                            error: revertedError(error, 2),
                        })
                    );
                });
            return;
        } catch (err) {
            console.log("err", err);
            const parsedError = JSON.stringify(err.message);
            if (parsedError.includes("reverted with reason ")) {
                return rejectWithValue(revertedError(err));
            }
            return rejectWithValue(err.message);
        }
    }
);

export const createFairLaunchIdoAction = createAsyncThunk(
    "idoApply/fairLaunchFactoryContract",
    async ({ chainId, account }, { getState, rejectWithValue, dispatch }) => {
        try {
            const _idoFairlaunchFactoryContract = fairLaunchFactoryContract({
                chainId,
            });
            if (!_idoFairlaunchFactoryContract) {
                return rejectWithValue("No contract found!");
            }

            const { idoApply } = getState();

            const { formDetails, preFee } = idoApply;

            const allocation = getTotalAmountOfApproveForFairLaunch({
                liquidityPc: formDetails?.liquidity,
                tokenForsale: formDetails?.tokenAllowcated,
            });

            console.log("********", formDetails);

            const idoDetails = {
                TokenAddress: formDetails?.tokenAddress,
                TokenAllocation: toWei(
                    formDetails?.tokenAllowcated,
                    formDetails?.decimals?.toString()
                ),
                SoftCap: toWei(
                    formDetails?.softCap,
                    formDetails?.decimals?.toString()
                ),
                BuyInLimit: formDetails?.buyinlimit === "true",
                BuyLimit:
                    formDetails?.buyinlimit === "true"
                        ? toWei(
                              formDetails?.minBuy,
                              formDetails?.decimals?.toString()
                          )
                        : 0,
                AutoListing: formDetails?.tokenListingType === "Auto",
                LiquidityPC: formDetails?.liquidity,
                Refund: formDetails?.refund === "refund",
                StartTime: unixTimeStamp(formDetails?.idoStartDate),
                EndTime: unixTimeStamp(formDetails?.idoEndDate),
            };

            //console.log("idoidoDetailsDe", idoDetails);
            const [symbolImg, coverImg] = await Promise.all([
                uploadToIpfs(formDetails?.projectCover),
                uploadToIpfs(formDetails?.coverImage),
            ]);

            await createIdoService({
                ...formDetails,
                projectCover: `${process.env.REACT_APP_IPFS_GATEWAY}${symbolImg?.data?.IpfsHash}`,
                coverImage: `${process.env.REACT_APP_IPFS_GATEWAY}${coverImg?.data?.IpfsHash}`,
                idoStartDate: new Date(formDetails?.idoStartDate).toISOString(),
                idoEndDate: new Date(formDetails?.idoEndDate).toISOString(),
            });
            console.log("reached to contract invocation");
            await _idoFairlaunchFactoryContract.methods
                .createIDO(idoDetails, PAIR_COIN_ADDRESS[formDetails?.pairCoin])
                .send(
                    { from: account, value: preFee },
                    async function (error, transactionHash) {
                        if (error) {
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_fairlaunch_createIdo",
                                        hash: null,
                                        status: "failed",
                                        result: {},
                                    },
                                    error: revertedError(error),
                                })
                            );
                            console.log("errrrrr", error);
                        } else {
                            // await createIdoService(formDetails);
                            dispatch(
                                idoApplyTransactionAction({
                                    transaction: {
                                        type: "idoApply_fairlaunch_createIdo",
                                        hash: transactionHash,
                                        status: "pending",
                                        result: {},
                                    },
                                })
                            );
                        }
                    }
                )
                .on("receipt", async function (receipt) {
                    console.log("receipt", receipt);
                    dispatch(
                        idoApplyTransactionAction({
                            transaction: {
                                type: "idoApply_fairlaunch_createIdo",
                                hash: null,
                                status: "success",
                                result: receipt,
                            },
                        })
                    );
                })
                .on("error", async function (error) {
                    console.log("error", error);
                    if (error?.code === 4001) return;
                    dispatch(
                        idoApplyTransactionAction({
                            transaction: {
                                type: "idoApply_fairlaunch_createIdo",
                                hash: null,
                                status: "failed",
                                result: {},
                            },
                            error: revertedError(error, 2),
                        })
                    );
                    console.log("errrrrr", error);
                });
            return;
        } catch (err) {
            const parsedError = JSON.stringify(err.message);
            if (parsedError.includes("reverted with reason ")) {
                return rejectWithValue(revertedError(err));
            }
            return rejectWithValue(err.message);
        }
    }
);

export const getPrefeeAction = createAsyncThunk(
    "idoApply/getPrefeeAction",
    async ({ chainId }, { getState, rejectWithValue }) => {
        try {
            const { idoApply } = getState();

            const { formDetails } = idoApply;

            let _factoryContract;

            if (formDetails?.offerType === IDO_TYPE.PRESALE) {
                _factoryContract = web3IdoFactoryContract({ chainId });
            } else {
                _factoryContract = fairLaunchFactoryContract({ chainId });
            }
            if (!_factoryContract) {
                return rejectWithValue("No contract found!");
            }

            const result = await _factoryContract.methods.preFee().call();
            console.log("result", result);
            return result?.toString();
        } catch (err) {
            console.log("err", err);
            toast.error("prefee Invalid token address");
            return rejectWithValue(err.message);
        }
    }
);

export const getPostfeeAction = createAsyncThunk(
    "idoApply/getPostfeeAction",
    async ({ chainId }, { getState, rejectWithValue }) => {
        try {
            const { idoApply } = getState();

            const { formDetails } = idoApply;

            let _factoryContract;

            if (formDetails?.offerType === IDO_TYPE.PRESALE) {
                _factoryContract = web3IdoFactoryContract({ chainId });
            } else {
                _factoryContract = fairLaunchFactoryContract({ chainId });
            }
            if (!_factoryContract) {
                return rejectWithValue("No contract found!");
            }

            const result = await _factoryContract.methods.postFeepc().call();
            console.log("result", result);
            return result?.toString();
        } catch (err) {
            console.log("err", err);
            toast.error("postfee Invalid token address");
            return rejectWithValue(err.message);
        }
    }
);
