import {
    checkAllowenceAction,
    confirmAllowenceAction,
    createFairLaunchIdoAction,
    createPresaleIdoAction,
    getPostfeeAction,
    getPrefeeAction,
    getTokenDetails,
} from "redux/actions/idoApply";

import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    transaction: {
        type: null, // swap/ add_liquidity, remove_liquidity
        hash: null,
        status: null, // pending, success, failed
        result: null, // transaction result on success, { token0:{symbol, address, amount  }  , token1: { symbol, address, amount }, priceRatio:token0/token1 }
    },
    error: null,
    formStep: 0,
    formDetails: {},
    allowence: "0.00",
    tokenApproved: false,
    preFee: "0.00",
    postFee: "0.00",
};

const idoSlice = createSlice({
    name: "idoApply",
    initialState,
    reducers: {
        idoApplyTransactionAction: (state, action) => {
            state.transaction = action.payload.transaction;
            if (action.payload?.error) {
                console.log("action.payload?.error");
                state.error = action.payload.error;
                state.loading = false;
                toast.error(action.payload.error);
            }
        },
        setPreviousStep: (state, action) => {
            state.formStep = state.formStep <= 0 ? 0 : state.formStep - 1;
        },
        setFormData: (state, { payload }) => {
            return {
                ...state,
                formDetails: { ...state.formDetails, ...payload },
                formStep: state.formStep >= 4 ? 4 : state.formStep + 1,
            };
        },
    },
    extraReducers: {
        [getTokenDetails.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [getTokenDetails.fulfilled]: (state, { payload }) => {
            // return {
            // 	...state,
            // 	loading: false,
            // 	formDetails: { ...state.formDetails, ...payload },
            // 	formStep: state.formStep >= 4 ? 4 : state.formStep + 1,
            // };
            state.loading = false;
            state.formDetails = { ...state.formDetails, ...payload };
            state.formStep = state.formStep >= 4 ? 4 : state.formStep + 1;
        },
        [getTokenDetails.rejected]: (state, { payload }) => {
            //console.log("state.formStep", state.formStep);
            state.loading = false;
            state.formStep = state.formStep;
            state.error = payload;
        },

        //checkAllowence
        [checkAllowenceAction.pending]: (state, action) => {
            state.loading = true;
        },
        [checkAllowenceAction.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.allowence = payload;
        },
        [checkAllowenceAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        //approve token
        [confirmAllowenceAction.pending]: (state, action) => {
            state.loading = true;
        },
        [confirmAllowenceAction.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.tokenApproved = payload;
        },
        [confirmAllowenceAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        //create presale ido
        [createPresaleIdoAction.pending]: (state, _) => {
            state.loading = true;
        },
        [createPresaleIdoAction.fulfilled]: (state, _) => {
            state.loading = false;
        },
        [createPresaleIdoAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        //create fairlaunch
        [createFairLaunchIdoAction.pending]: (state, _) => {
            state.loading = true;
        },
        [createFairLaunchIdoAction.fulfilled]: (state, _) => {
            state.loading = false;
        },
        [createFairLaunchIdoAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        //fees
        [getPrefeeAction.pending]: (state, _) => {
            state.loading = true;
        },
        [getPrefeeAction.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.preFee = payload;
        },
        [getPrefeeAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },

        [getPostfeeAction.pending]: (state, _) => {
            state.loading = true;
        },
        [getPostfeeAction.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.postFee = payload;
        },
        [getPostfeeAction.rejected]: (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const idoReducer = idoSlice.reducer;

export const {
    // setNextStep,
    setPreviousStep,
    setFormData,
    idoApplyTransactionAction,
} = idoSlice.actions;

export const idoState = (state) => state?.idoApply;
