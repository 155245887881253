import { combineReducers } from "redux";
import { idoReducer } from "./idoApplySlice";
import { idoPresaleReducer } from "./idoPresaleSlice";
import { idoApi } from "./idoDataSlice";
import { lockApi } from "./lockDataSlice";
import { idoReducerold } from "./idoSlice";

export const rootReducer = combineReducers({
    idoApply: idoReducer,
    presale: idoPresaleReducer,
    ido: idoReducerold,
    [idoApi.reducerPath]: idoApi.reducer,
    [lockApi.reducerPath]: lockApi.reducer,
});
