import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./reducers/rootreducer";
import { setupListeners } from "@reduxjs/toolkit/query";
import { idoApi } from "./reducers/idoDataSlice";
import { lockApi } from "./reducers/lockDataSlice";
// import storage from "redux-persist/lib/storage";
// import {
// 	FLUSH,
// 	PAUSE,
// 	PERSIST,
// 	PURGE,
// 	REGISTER,
// 	REHYDRATE,
// 	persistReducer,
// 	persistStore,
// } from "redux-persist";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// const persistConfig = {
// 	key: "root",
// 	storage,
// 	stateReconciler: autoMergeLevel2,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
// 	reducer: persistedReducer,
// 	middleware: (getDefaultMiddleware) =>
// 		getDefaultMiddleware({
// 			serializableCheck: {
// 				ignoredActions: [
// 					FLUSH,
// 					REHYDRATE,
// 					PAUSE,
// 					PERSIST,
// 					PURGE,
// 					REGISTER,
// 				],
// 			},
// 		}),
// });

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(idoApi.middleware, lockApi.middleware),
});

// export const persistor = persistStore(store);

// setupListeners(store.dispatch)
