import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { serialize } from "utils";

export const idoApi = createApi({
	reducerPath: "idoApi",
	baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
	tagTypes: ["IDOS"],
	endpoints: (builder) => ({
		listIdos: builder.query({
			query: ({ page = 1, search, status, sort, type, chain }) => {
				return `/ido?${serialize({
					page,
					search,
					status,
					sort: sort?.value,
					type: type?.value,
					chain,
				})}`;
			},
			serializeQueryArgs: ({ endpointName }) => {
				return endpointName;
			},
			// transformResponse: (response, meta, arg) => response,
			merge: (currentCache, newItem) => {
				console.log("currentCache", currentCache.data);
				console.log("newItem", newItem);
				currentCache.data.push(...newItem?.data);
			},
			forceRefetch({ currentArg, previousArg }) {
				return currentArg !== previousArg;
			},
			providesTags: ["IDOS"],
		}),
	}),
});

export const { useListIdosQuery } = idoApi;
