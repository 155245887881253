import { createAsyncThunk } from "@reduxjs/toolkit";
import { web3IdoPresaleContract } from "contract";
import { presaleTransactionAction } from "redux/reducers/idoPresaleSlice";
import { revertedError, toWei, unixTimeStamp } from "utils";

export const getPresalePoolInfoAction = createAsyncThunk(
	"presale/getValueRaisedAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});

			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			console.log("_idoContract", _idoContract);
			const [valueRaised, totalTokenSold, idoDetails, userRecord] =
				await Promise.all([
					_idoContract.methods.valueRaised().call(),
					_idoContract.methods.totalTokenSold().call(),
					_idoContract.methods.getIdoDetails().call(),
					_idoContract.methods.userRecord(payload?.account).call(),
				]);

			return {
				valueRaised,
				totalTokenSold,
				idoDetails,
				userRecord,
			};
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

export const getIsFundClaimedAction = createAsyncThunk(
	"presale/getIsFundClaimedAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			const result = await _idoContract.methods.getIsFundClaimed().call();
			return result;
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

export const getIsDexPoolAvailableAction = createAsyncThunk(
	"presale/getIsDexPoolAvailableAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			const result = await _idoContract.methods
				.isDexPoolAvailable()
				.call();
			return result;
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

//isCancel
export const getIsCancelAction = createAsyncThunk(
	"presale/getIsCancelAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			const result = await _idoContract.methods.isCancel().call();
			return result;
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

//whiteList
export const getIswhiteListAction = createAsyncThunk(
	"presale/getIswhiteListAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			const result = await _idoContract.methods
				.whiteList(payload?.account)
				.call();
			return result;
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

//isIdoSuccesful
export const getIsIdoSuccesfulAction = createAsyncThunk(
	"presale/getIsIdoSuccesfulAction",
	async (payload, { rejectWithValue }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			const result = await _idoContract.methods.isIdoSuccesful().call();
			return result;
		} catch (err) {
			console.log("err", err);
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

//cancle ido
export const cancelPresaleIDOAction = createAsyncThunk(
	"ido/cancelPresaleIDOAction",
	async (payload, { rejectWithValue, dispatch }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}

			await _idoContract.methods
				.cancelIDO()
				.send(
					{ from: payload?.account },
					function (error, transactionHash) {
						if (error) {
							dispatch(
								presaleTransactionAction({
									transaction: {
										type: "presale_ido_cancle",
										hash: null,
										status: "failed",
										result: {},
									},
									error: revertedError(error),
								})
							);
						} else {
							dispatch(
								presaleTransactionAction({
									transaction: {
										type: "presale_ido_cancle",
										hash: transactionHash,
										status: "pending",
										result: {},
									},
								})
							);
						}
					}
				)
				.on("receipt", async function (receipt) {
					console.log("receipt", receipt);
					dispatch(
						presaleTransactionAction({
							transaction: {
								type: "presale_ido_cancle",
								hash: null,
								status: "success",
								result: receipt,
							},
						})
					);
				})
				.on("error", async function (error) {
					console.log("error", error);
					presaleTransactionAction({
						transaction: {
							type: "presale_ido_cancle",
							hash: null,
							status: "failed",
							result: {},
						},
						error: revertedError(error, 2),
					});
				});
			return;
		} catch (err) {
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);

//update ido
export const updatePresaleIDOAction = createAsyncThunk(
	"ido/updatePresaleIDOAction",
	async (payload, { rejectWithValue, dispatch }) => {
		try {
			let _idoContract = web3IdoPresaleContract({
				address: payload?.ido?.tokenAddress,
				chainId: payload?.chainId,
			});
			if (!_idoContract) {
				return rejectWithValue("No contract found!");
			}
			const inputs = {
				_MinBuyPerUser: toWei(payload?.inputs?.minBuy),
				_MaxBuyPerUse: toWei(payload?.inputs?.maxBuy),
				_StartTime: unixTimeStamp(payload?.inputs?.startDate),
				_EndTime: unixTimeStamp(payload?.inputs?.endDate),
				_UseWhiteList: payload?.inputs?.whiteList,
			};

			await _idoContract.methods
				.updateIdoInfo(...inputs)
				.send(
					{ from: payload?.account },
					function (error, transactionHash) {
						if (error) {
							dispatch(
								presaleTransactionAction({
									transaction: {
										type: "presale_ido_cancle",
										hash: null,
										status: "failed",
										result: {},
									},
									error: revertedError(error),
								})
							);
						} else {
							dispatch(
								presaleTransactionAction({
									transaction: {
										type: "presale_ido_cancle",
										hash: transactionHash,
										status: "pending",
										result: {},
									},
								})
							);
						}
					}
				)
				.on("receipt", async function (receipt) {
					console.log("receipt", receipt);
					dispatch(
						presaleTransactionAction({
							transaction: {
								type: "presale_ido_cancle",
								hash: null,
								status: "success",
								result: receipt,
							},
						})
					);
				})
				.on("error", async function (error) {
					console.log("error", error);
					presaleTransactionAction({
						transaction: {
							type: "presale_ido_cancle",
							hash: null,
							status: "failed",
							result: {},
						},
						error: revertedError(error, 2),
					});
				});
			return;
		} catch (err) {
			const parsedError = JSON.stringify(err.message);
			if (parsedError.includes("reverted with reason ")) {
				return rejectWithValue(revertedError(err));
			}
			return rejectWithValue(err.message);
		}
	}
);
