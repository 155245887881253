import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
	getPresalePoolInfoAction,
	getIsFundClaimedAction,
	getIsDexPoolAvailableAction,
	getIsCancelAction,
	getIswhiteListAction,
	getIsIdoSuccesfulAction,
	cancelPresaleIDOAction,
	updatePresaleIDOAction,
} from "redux/actions/idoPresaleAction";

const initialState = {
	loading: false,
	transaction: {
		type: null, // swap/ add_liquidity, remove_liquidity
		hash: null,
		status: null, // pending, success, failed
		result: null, // transaction result on success, { token0:{symbol, address, amount  }  , token1: { symbol, address, amount }, priceRatio:token0/token1 }
	},
	presalePoolInfo: {},
	isFundClaimed: false,
	isDexPoolAvailable: false,
	isCancel: false,
	whiteList: false,
	isIdoSuccesful: false,
	error: null,
};

const idoPresaleSlice = createSlice({
	name: "presale",
	initialState,
	reducers: {
		presaleTransactionAction: (state, action) => {
			console.log("action", action);
			state.transaction = action.payload.transaction;
			if (action.payload?.error) {
				state.error = action.payload.error;
				toast.error(action.payload.error);
			}
		},
	},
	extraReducers: {
		[getPresalePoolInfoAction.pending]: (state, _) => {
			state.loading = true;
		},
		[getPresalePoolInfoAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.presalePoolInfo = payload;
		},
		[getPresalePoolInfoAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//isFundClaimed
		[getIsFundClaimedAction.pending]: (state, action) => {
			state.loading = true;
		},
		[getIsFundClaimedAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.isFundClaimed = payload;
		},
		[getIsFundClaimedAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//isDexPoolAvailable
		[getIsDexPoolAvailableAction.pending]: (state, action) => {
			state.loading = true;
		},
		[getIsDexPoolAvailableAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.isDexPoolAvailable = payload;
		},
		[getIsDexPoolAvailableAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//isCancel
		[getIsCancelAction.pending]: (state, action) => {
			state.loading = true;
		},
		[getIsCancelAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.isCancel = payload;
		},
		[getIsCancelAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		// getIswhiteListAction
		[getIswhiteListAction.pending]: (state, action) => {
			state.loading = true;
		},
		[getIswhiteListAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.whiteList = payload;
		},
		[getIswhiteListAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//getIsIdoSuccesfulAction
		[getIsIdoSuccesfulAction.pending]: (state, action) => {
			state.loading = true;
		},
		[getIsIdoSuccesfulAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.isIdoSuccesful = payload;
		},
		[getIsIdoSuccesfulAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//cancelPresaleIDOAction
		[cancelPresaleIDOAction.pending]: (state, action) => {
			state.loading = true;
		},
		[cancelPresaleIDOAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
		},
		[cancelPresaleIDOAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//updatePresaleIDOAction
		[updatePresaleIDOAction.pending]: (state, action) => {
			state.loading = true;
		},
		[updatePresaleIDOAction.fulfilled]: (state, { payload }) => {
			state.loading = false;
		},
		[updatePresaleIDOAction.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
	},
});

export const idoPresaleReducer = idoPresaleSlice.reducer;

export const { presaleTransactionAction } = idoPresaleSlice.actions;

export const presaleState = (state) => state?.presale;
