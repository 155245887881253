import "./App.css";
import { Suspense, lazy, useEffect } from "react";
import Loader from "components/Loader/Loader";
import { Route, Routes } from "react-router-dom";
import { injected } from "connection";
import { useWeb3React } from "@web3-react/core";

const Home = lazy(() => import("components/Home/Home"));
const IDOApply = lazy(() => import("components/IdoApply/IdoApply"));
const Idolist = lazy(() => import("components/IdoList/Idolist"));
const IdoDetails = lazy(() => import("components/IdoList/IdoDetails"));
const IdoPool = lazy(() => import("./components/IdoList/IdoPool"));
const ClaimToken = lazy(() => import("./components/IdoList/ClaimToken"));
const AirdropCreate = lazy(() => import("./components/AirDrop/AirdropCreate"));
const LockCreate = lazy(() => import("./components/LockCreate/LockCreate"));
const Withdraw = lazy(() => import("./components/IdoOwner/Withdraw"));
const Unlock = lazy(() => import("./components/LockCreate/Unlock"));
const LockDetails = lazy(() => import("./components/LockCreate/LockDetails"));

function App() {
    const { activate } = useWeb3React();
    useEffect(() => {
        const wallet = localStorage.getItem("wallet");
        switch (wallet) {
            case "meta":
                activate(injected);
                break;
            default:
                break;
        }
    }, []);
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<IDOApply />} />
                <Route path="/idos-list" element={<Idolist />} />
                <Route path="/ido-details" element={<IdoDetails />} />
                <Route path="/pool" element={<IdoPool />} />
                <Route path="/airdrop" element={<AirdropCreate />} />
                <Route path="/ido-claim" element={<ClaimToken />} />
                <Route path="/lock-create" element={<LockCreate />} />
                <Route path="/Unlock" element={<Unlock />} />
                <Route path="/lock-details" element={<LockDetails />} />
                <Route path="/ido-withdraw" element={<Withdraw />} />
            </Routes>
        </Suspense>
    );
}

export default App;
