import {
    RPC_URLS,
    IDO_PRE_SALE_FACTORY_ADDRESS,
    IDO_FAIRLAUNCH_FACTORY_ADDRESS,
    AIRDROP_ADDRESS,
    LOCK_ADDRESS,
} from "constant";
import { isMetaMaskInstalled } from "utils";
import IDOFACTORY from "abi/IdoPreSaleFactory.json";
import IDOPRESALE from "abi/IdoPresale.json";
import ERC20 from "abi/ERC20.json";
import AIRDROP from "../abi/AirDrop.json";
import LOCK from "../abi/LogisticLock.json";

//fairlaunch
import FAIRLAUNCH from "abi/FairLaunch.json";
import FAIRLAUNCHFACTORY from "abi/FairLaunchFactory.json";

import { Contract } from "@ethersproject/contracts";
import Web3 from "web3";

const getContract = (abi, contractAddress, library) => {
    if (isMetaMaskInstalled()) {
        return new Contract(contractAddress, abi, library?.getSigner());
    }
    return;
};

export const erc20Contract = ({ address, library }) => {
    if (!address) {
        return null;
    }
    return getContract(ERC20, address, library);
};

export const getWeb3Contract = (chainId, abi, contractAddress) => {
    let web3;

    if (isMetaMaskInstalled()) {
        web3 = new Web3(window.ethereum);
    } else {
        web3 = new Web3(new Web3.providers.HttpProvider(RPC_URLS?.[chainId]));
    }

    return new web3.eth.Contract(abi, contractAddress);
};

export const getbalance = async ({ chainId, address }) => {
    let web3;

    if (isMetaMaskInstalled()) {
        web3 = new Web3(window.ethereum);
    } else {
        web3 = new Web3(new Web3.providers.HttpProvider(RPC_URLS?.[chainId]));
    }
    const result = await web3.eth.getBalance(address);
    return result;
};

export const web3Erc20Contract = ({ chainId, address }) => {
    return getWeb3Contract(chainId, ERC20, address);
};

export const web3IdoFactoryContract = ({ chainId }) => {
    const address = IDO_PRE_SALE_FACTORY_ADDRESS[chainId];
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, IDOFACTORY, address);
};

export const web3IdoPresaleContract = ({ address, chainId }) => {
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, IDOPRESALE, address);
};

//fairlaunch
export const fairLaunchContract = ({ address, chainId }) => {
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, FAIRLAUNCH, address);
};

export const fairLaunchFactoryContract = ({ chainId }) => {
    const address = IDO_FAIRLAUNCH_FACTORY_ADDRESS[chainId];
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, FAIRLAUNCHFACTORY, address);
};

export const airdropcontract = ({ chainId }) => {
    const address = AIRDROP_ADDRESS[chainId];
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, AIRDROP, address);
};

export const lockcontract = ({ chainId }) => {
    const address = LOCK_ADDRESS[chainId];
    if (!address) {
        return null;
    }

    return getWeb3Contract(chainId, LOCK, address);
};
